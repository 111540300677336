/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import styled, {css } from 'styled-components'
import dayjs from 'dayjs';
import Datepicker from 'react-datepicker';
import parse from 'html-react-parser'

// Elements
import { LabelCSS, InputCSS, TextareaCSS } from 'components/elements/Form'
import ParseContent from 'components/shared/ParseContent'

// Libraries
import { ErrorMessage, FieldArray } from 'formik'
import Select from 'react-select'

const StyledInput = styled.input`
  ${InputCSS};
`

const StyledDatepicker = styled(Datepicker)`
  ${InputCSS};
`

const StyledTextArea = styled.textarea`
  ${TextareaCSS};
`

const OptionCSS = css`
  font-size: ${props => props.theme.font.size.m};
  font-weight: ${props => props.theme.font.weight.s};
  display: flex;
  align-items: center;
  justify-content: start;
  padding-right: 10px;

  & > span {
    text-align: left;
    text-decoration: ${props => props.isActive ? 'underline' : 'none'};
  }
`

const StyledLabel = styled.label`
  color: ${props => props.theme.color.text.grey};
  font-weight: ${props => props.theme.font.weight.s};
`

const Option = styled.div`
  ${OptionCSS};
  color: ${props => props.theme.color.text.main};
`

const LabelNumber = styled.p`
  font-size: ${props => props.theme.font.size.xm};
  color: ${props => props.theme.color.text.contrast};
  font-weight: ${props => props.theme.font.weight['700']};
`

export const Checkbox = styled.div`
  border: 1px solid ${props => props.theme.color.background};
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`

export const Check = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 13px;
  width: 7px;
  border-bottom: 3px solid ${props => props.theme.color.secondary};
  border-right: 3px solid ${props => props.theme.color.secondary};
`

export const Error = styled(ErrorMessage)`
  background-color: ${props => props.theme.color.contrast};
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size.sm};
  margin: 0 auto;
  width: 50%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
`

const Input = ({ item, data, setData, setFieldValue, isCustom, fullWidth }) => {
  let value = '';

  const [itemsWithValue, setItemsWithValue] = useState([{name: '', value: ''}])

  if(data[`input_${item.id}`]) {
    value = data[`input_${item.id}`]
  }

  if(item.type === 'html'){
    return (
      <div className="my-5 d-flex justify-content-center">
        {parse(item.content)}
      </div>
    )
  }

  if(item.type === 'select'){

    // Populate a options array
    const options = []

    JSON.parse(item.choices).forEach(choice => {
      options.push({
        value: choice.value,
        label: choice.text,
        type: 'select',
        name: `input_${item.id}`
      })
    })
  
    return (
      <div className={`field_${item.id} d-flex flex-wrap align-items-center mb-4`}>
        <div className="col-lg-5 d-flex">
          <StyledLabel htmlFor={`input_${item.id}`}>{item.label}</StyledLabel>
        </div>
        <div className="col-lg-7 d-flex">
          <div className="w-100">
            <Select
              options={options}
              onChange={(e) => {
                const event = { target: { name: `input_${item.id}`, value: e.value}}
                                    
                setData(event)
                setFieldValue(event.target.name, event.target.value)
              }}
              value={options.filter(option => option.value === value)}
              placeholder={item.placeholder}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <Error
          name={`input_${item.id}`}
          component="div"
          className="py-1 px-3"
        />
      </div>
    )
  }

  useEffect(() => {
    let formValue = ''

    itemsWithValue.forEach((item) => {
      formValue += `- ${item.name} (${item.value}) \n`
    })

    setFieldValue('input_6', formValue, false)
  }, [itemsWithValue])

  if(item.id === 5) {
    return (
      <div className={`field_${item.id} d-flex flex-wrap align-items-center justify-space-between mb-4`}>
        <div className="col-lg-12 d-flex">
          <StyledLabel htmlFor={`input_${item.id}`}>{item.label}</StyledLabel>
        </div>
        <div className="col-lg-12">
          {itemsWithValue.map((item, index) => (
            <div key={index} className="d-flex flex-wrap flex-lg-nowrap justify-content-between">
              <StyledInput type={item.type}  className="col-lg-7" value={item.name} onChange={(event) => {
                const newItemsWithValue = [...itemsWithValue]
                newItemsWithValue[index].name = event.target.value
                setItemsWithValue(newItemsWithValue)
              }} />
              <StyledInput type={item.type} className="col-lg-4" value={item.value} onChange={(event) => {
                const newItemsWithValue = [...itemsWithValue]
                newItemsWithValue[index].value = event.target.value
                setItemsWithValue(newItemsWithValue)
              }} />
              <button type="button" onClick={() => {
                const newItemsWithValue = [...itemsWithValue]
                newItemsWithValue.splice(index, 1)
                setItemsWithValue(newItemsWithValue)
              }}>
                Remove
              </button>
            </div>
          ))}
          <button type="button" onClick={() => {
            const newItemsWithValue = [...itemsWithValue]
            newItemsWithValue.push({
              name: '', value: ''
            })
            setItemsWithValue(newItemsWithValue)
          }}>
            Add
          </button>
        </div>
      </div>
    )
  }

  if(item.type === 'textarea') {
    return (
      <div className={`field_${item.id} d-flex flex-wrap align-items-center justify-space-between mb-4`}>
        <div className="col-lg-5 d-flex">
          <StyledLabel htmlFor={`input_${item.id}`}>{item.label}</StyledLabel>
        </div>
        <div className="col-lg-7  d-flex">
          <StyledTextArea
            id={`input_${item.id}`}
            value={value}
            type={item.type}
            onChange={event => {
              setData(event)
              setFieldValue(event.target.name, event.target.value)
            }}
            name={`input_${item.id}`}
          />
          <Error
            name={`input_${item.id}`}
            component="div"
            className="py-1 px-3"
          />
        </div>
      </div>
    )
  }

  
  return (
    <div className={`field_${item.id} d-flex flex-wrap align-items-center justify-space-between mb-4`}>
      <div className="col-lg-5 d-flex">
        <StyledLabel htmlFor={`input_${item.id}`}>{item.label}</StyledLabel>
      </div>
      <div className="col-lg-7  d-flex">
        <StyledInput
          id={`input_${item.id}`}
          value={value}
          type={item.type}
          onChange={event => {
            setData(event)
            setFieldValue(event.target.name, event.target.value)
          }}
          name={`input_${item.id}`}
          placeholder={item.placeholder}
        />
        <Error
          name={`input_${item.id}`}
          component="div"
          className="py-1 px-3"
        />
      </div>
    </div>
  )   
}

export default Input